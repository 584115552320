import React from 'react'

import styled from 'styled-components';

import Title from '../Elements/Title';
import { BREAKPOINT, useDevice } from '../hooks/DeviceContext';
import Credits from './Credits';
import Divider from '../Elements/Divider';

export default function Contacts() {
  const { isMobile } = useDevice();
  return (
    <Wrapper>
      <Title
        style={isMobile ? {
          marginBottom: '1.875rem',
          lineHeight: '2.125rem',
        } : {
          marginBottom: '1.25rem',
        }}
      >Get in touch</Title>
      <Primary>
        <a
        href='mailto:yulia.svischeva@gmail.com'>
          yulia.svischeva<span>@</span>gmail.com
        </a>
      </Primary>
      <Secondary>
        <a target='_blank' href='https://www.behance.net/someillumination' rel="noreferrer"
        >
          Behance
        </a>
        <a target='_blank' href='https://t.me/yulchiksv' rel="noreferrer">
          Telegram
        </a>
      </Secondary>
      {isMobile &&  (
      <>
        <Divider style={{margin: '0', marginBottom: '1rem'}} />
        <Credits />
      </>
    )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 1.875rem;
  padding: 0 var(--padding-side-mob);

  img {
    margin-left: 0.25rem;
    height: 1.188rem;
    transform: rotate(-90deg);
  }

  @media (min-width: ${BREAKPOINT}px) {
    padding: 0;
  }
`;

const Primary = styled.div`
  margin-bottom: 1rem;

  a {
    display: block;
    font-size: 1.25rem;
    font-family: var(--third-font);
    line-height: 1.563rem;
    font-weight: 400;
    color: var(--blue);
    text-decoration: none;
  }

  a > span {
    font-family: var(--secondary-font);
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin-bottom: 1.25rem;
  }
`;

const Secondary = styled.div`
  display: flex;
  gap: 1.875rem;
  margin-bottom: 1.75rem;

  a {
    border-radius: 3.5rem;
    border: 0.125rem solid var(--blue);
    padding: 0.625rem 2.125rem;

    font-size: 1rem;
    font-family: var(--main-font);
    font-style: italic;
    line-height: 1.313rem;
    font-weight: 700;
    color: var(--blue);
    text-decoration: none;
  }

  a:hover {
    background: var(--blue);
    color: var(--bubble-text-color);
  }

  @media (min-width: ${BREAKPOINT}px) {
    gap: 1.063rem;
    margin-bottom: 1.25rem;

    a {
      padding: 0.75rem 2.75rem;

      font-size: 1.063rem;
      line-height: 1.313rem;
    }
  }
`;
