import React from 'react'
import styled from 'styled-components'
import Project from './Project';
import Divider from '../Elements/Divider';
import Title from '../Elements/Title';
import { BREAKPOINT, useDevice } from '../hooks/DeviceContext';
import Credits from './Credits';

export default function Main() {
  const { isMobile } = useDevice();
  const projects = require('../data/projects.json');
  const skills = require('../data/skills.json');
  const experience = require('../data/experience.json');
  return (
    <Wrapper>
      {projects.map((project, index) => (
        <section key={index}>
        <Project 
          title={project.title}
          titleColor={project.titleColor}
          subtitle={project.subtitle}
          description={project.description}
          images={project.images}
          link={project.link}
          linkText={project.linkText}
          linkTextExtra={project.linkTextExtra}
          style={
            isMobile ? {} : {
              marginTop: index === 0 ? '0' : '2rem',
              marginBottom: '2rem'
            }
          }
        />
        <Divider style={!isMobile ? {margin: '0'} : {}} />
        </section>
      ))}

      <Section>
        <Title
          style={{
            marginBottom: '1.5rem',
          }}
        >Skills & areas</Title>
        <BulletPoints className='skills'>
          {skills.map((skill, index) => (
            <li key={index}>
              <Skill key={index}>{skill}</Skill>
            </li>
          ))}
        </BulletPoints>
      </Section>

      <Divider style={!isMobile ? {margin: '0'} : {}} />

      <Section>
        <Title
          style={{
            marginBottom: '1.5rem',
          }}
        >Experience</Title>
        <BulletPoints>
          {experience.bulletPoints?.map((exp, index) => (
            <li key={index} className='experience'>
              <BulletPoint />
              <Item dangerouslySetInnerHTML={{__html: exp}} />
            </li>
          ))}
        </BulletPoints>
        <Collaborations>
          {experience.collaborations?.map((collab, index) => (
            <Item key={index} dangerouslySetInnerHTML={{__html: collab}} />
          ))}
        </Collaborations>
      </Section>

      <Divider style={!isMobile ? {margin: '0'} : {}} />

      <Section>
        <Title
          style={{
            marginBottom: '2.5rem',
          }}>
            Also, I create risographic posters like this:
        </Title>
        
        <Posters>
          <img src='https://res.cloudinary.com/duhyhdj20/image/upload/v1740931045/site/Riso%20posters/3460_001_page-0001_g2dzwd.jpg' alt='' />
          <img src='https://res.cloudinary.com/duhyhdj20/image/upload/v1740931045/site/Riso%20posters/3459_001_page-0001_v7wkqa.jpg' alt='' />
        </Posters>
      </Section>
      
      <Divider style={!isMobile ? {margin: '0'} : {}} />

      <Section>
        <Title
          style={{
            marginBottom: '2.5rem',
          }}>
        Thanks for watching and have a creative day ;-)
        </Title>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <img 
            style={{
              width: "9.688rem"
            }}
          src="https://res.cloudinary.com/duhyhdj20/image/upload/v1740931083/site/My%20photo/Yulia.png" alt="" />
        </div>
      </Section>

      <Divider style={!isMobile ? {margin: '0', marginBottom: '1.25rem'} : {}} />
      {!isMobile && <Credits />}

    </Wrapper>
  )
}

const Wrapper = styled.main`
  margin-top: 0.625rem;

  @media (min-width: ${BREAKPOINT}px) {
    margin: 0;
  }
`;

const Section = styled.section`
  padding: 1.875rem var(--padding-side-mob);

  @media (min-width: ${BREAKPOINT}px) {
    padding: 2rem 0;
  }
`;

const BulletPoints = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  flex-wrap: wrap;

  padding: 0;
  list-style: none;

  & li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  & li.experience {
    align-items: flex-start;

    span {
      padding-top: calc((1.938rem - 1.5rem) / 2); // center bullet around the first line
    }
  }

  @media (min-width: ${BREAKPOINT}px) {
    &.skills {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;
    }

    & li.experience {
      align-items: center;

      span {
        padding-top: 0;
      }
    }
  }
`;

const BulletPoint = styled.span`
  border-radius: 2.125rem;
  width: 3.875rem;
  height: 1.938rem;
  flex-shrink: 0;
  background: var(--green);
`;

const Skill = styled.span`
  padding: 0.25rem 1.875rem 0.438rem 3.375rem;
  background: var(--green);
  border-radius: 2.125rem;
  
  font-size: 1.25rem;
  font-family: var(--main-font);
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--bubble-text-color);

`;

const Item = styled.span`
  font-size: 1.25rem;
  font-family: var(--main-font);
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--blue);

  & em {
    font-weight: 700;
    font-style: italic;
  }
`;

const Collaborations = styled.ul`
  margin-top: 1.25rem;
  padding-left: 4.625rem;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  list-style: none;

  @media (min-width: ${BREAKPOINT}px) {
    gap: 1rem;
  }
`;

const Posters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  img {
    width: 100%;
  }

  @media (min-width: ${BREAKPOINT}px) {
    flex-direction: row;
    gap: 0;

    img {
      width: 18.75rem;
    }
  }
`;