import styled from 'styled-components';
import Header from '../Components/Header';
import Main from '../Components/Main';
import Contacts from '../Components/Contacts';

export default function HomePageDesk() {
  return (
    <Wrapper>
      <Left>
        <Header />
        <Contacts />
      </Left>
      <Right>
        <Main />
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 0 0 var(--padding-left-desk);

  display: flex;
  justify-content: space-between;
  gap: 2rem;
  height: 100vh;
  overflow: hidden;
`;

const Left = styled.div`
  padding-top: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 26rem;
`;

const Right = styled.div`
  padding: 1.875rem 3.75rem 3.75rem 0;
  overflow-y: auto;
  height: 100%;
  width: 50.75rem;
`;