import React from 'react'

import styled from 'styled-components';
import { BREAKPOINT } from '../hooks/DeviceContext';

export default function Subtitle({style, children}) {
  return (
    <Wrapper style={style}>
      <span>{children}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: fit-content;

  margin-bottom: 2.5rem;
  margin-right: var(--padding-side-mob);
  margin-left: 0.75rem;
  padding: 0.125rem 0.5rem;

  color: var(--purple);

  font-size: var(--font-size-l-mob);
  font-family: var(--third-font);
  font-weight: 400;
  line-height: 1.25rem;

  background: #FFFE39;

  img {
    height: 1.188rem;
    transform: rotate(-90deg);
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin-top: 1rem;
    margin-bottom: 4.375rem;
    margin-right: 0;
    padding: 0.125rem 0.438rem;
    
    font-size: 1.063rem;
    line-height: 1.313rem;
  }
`;