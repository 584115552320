import React from 'react'

import styled from 'styled-components';

import Title from '../Elements/Title';
import Subtitle from '../Elements/Subtitle';
import Bio from '../Elements/Bio';
import Divider from '../Elements/Divider';
import { useDevice } from '../hooks/DeviceContext';

import arrow from '../assets/header-arrow.svg';

export default function Header() {
  const { isMobile } = useDevice();
  return (
    <Wrapper>
      <Title
        style={ isMobile ? {
          padding: '0 var(--padding-side-mob)'
        } : {}}
      >Yulia Svischeva</Title>
      <Subtitle style={ isMobile ? {
      } : {
        position: 'relative',
        left: '-0.875rem',
      }}>graphic and digital designer in Berlin
      </Subtitle>
      <Bio>
        <img src={arrow} alt='' />
        <span>I create visual identities and cohesive visual narratives, both in print and digital, and work worldwide.</span>
      </Bio>
      {isMobile && <Divider />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


