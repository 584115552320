import React from 'react'

import styled from 'styled-components';

export default function Title({style, children}) {
  return (
    <TITLE style={style}>{children}</TITLE>
  )
}

const TITLE = styled.h1`
  font-size: var(--font-size-xl-mob);
  font-family: var(--main-font);
  font-weight: 400;
  line-height: 2.125rem;
  color: var(--blue);

  @media (min-width: 768px) {
    font-size: var(--font-size-xl-desk);
    line-height: 2.375rem;
  }
`;
