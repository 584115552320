import React from 'react'

import styled from 'styled-components';
import { BREAKPOINT } from '../hooks/DeviceContext';

export default function Bio({children}) {
  return (
    <BIO>
      {children}
    </BIO>
  )
}

const BIO = styled.div`
  margin-bottom: 2.5rem;
  padding: 0 var(--padding-side-mob);

  font-size: var(--font-size-l-mob);
  font-family: var(--main-font);
  font-weight: 200;
  line-height: 1.25rem;
  color: var(--blue);

  img {
    height: 1.188rem;
    rotate: -90deg;
    margin-right: 1rem;
    position: relative;
    top: 0.25rem;
    left: 0.25rem;
  }

  span {
    margin-top: 0.5rem;
    font-family: var(--third-font);
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin: 0;
    padding: 0;
    padding-left: calc(2.5rem - var(--padding-left-desk));

    width: 25rem;
    font-size: var(--font-size-l-desk);
    line-height: 1.5rem;

    img {
      height: 1.188rem;
      margin-right: 1rem;
      top: 0.15rem;
      left: 0.25rem;
    }
  }

  @media (min-width: ${BREAKPOINT}px) and (max-width: 1330px) {
    width: 23rem;
  }
`;
